<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px">
      <CCol>
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickNewLink()">
          Buat Link baru
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_edit="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
        <template #show_delete="{item, index}">
          <td class="py-2">
            <CButton
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="onClickDelete(item, index)">
              Delete
            </CButton>
          </td>
        </template>      
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialog" :title="title" hide-footer>
    <div style="padding: 10px">
      <CInput
        label="Name"
        v-model="record.name"
        horizontal
      />
      <CInput
        label="Url"
        v-model="record.url"
        horizontal
      />
      <CSelect
        id="selectAccess"
        :options="accessOptions"
      />
    </div>

    <div slot="footer">
      <CButton @click="dialogClose()">Batal</CButton>
      <CButton @click="dialogSave()" color="success">Simpan</CButton>
    </div>
  </CModal>
</div>
</template>

<script>
  import api from "../../api/api"

  const fields = [
    { 
      key: 'name', 
      label: 'Nama link', 
      _style: 'min-width:150px'
    },
    { 
      key: 'url', 
      label: 'Url', 
      _style: 'min-width:250px'
    },
    { 
      key: 'access', 
      label: 'Akses', 
      _style: 'min-width:20px'
    },
    { 
      key: 'show_edit', 
      label: '', 
      _style: 'width:1%'
    },
    { 
      key: 'show_delete', 
      label: '', 
      _style: 'width:1%'
    }
  ]

  export default {
    data () {
      return {
        showLoading: false,
        items: [],
        fields,

        showDialog: false,
        title: '',
        isEdit: true,
        record: '',
        user: '',
        accessOptions: ["USER", "PEMBIMBING", "PEMBINA"]
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listLink()
      }
    },

    methods: {
      async listLink () {
        this.items = []
        this.showLoading = true
        let apiRes = await api.listLink(this.user.id)
        this.showLoading = false

        if (apiRes.result === 'OK'){
          this.items = apiRes.links
          for(var i=0; i<this.items.length; i++){
            if(this.items[i].access==="LEADER") this.items[i].access="PEMBIMBING"
            if(this.items[i].access==="ADMIN") this.items[i].access="PEMBINA"
          }
        }
        else {
          console.log("listLink ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onClickEdit (item) {
        this.isEdit = true
        this.title = "Edit link"
        this.record = item
        if(item.access === "PEMBINA")
          document.getElementById("selectAccess").selectedIndex = 2
        else if(item.access === "PEMBIMBING")
          document.getElementById("selectAccess").selectedIndex = 1
        else 
          document.getElementById("selectAccess").selectedIndex = 0
        this.showDialog = true
      },

      async onClickDelete (item) {
        this.record = item
        this.$confirm("Yakin untuk menghapus link ini?").then(() => {
          this.executeDeleteLink()
        });
      },

      async executeDeleteLink(){
        this.showLoading = true
        let apiRes = await api.deleteLink(this.user.id, this.record.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Record sudah dihapus!")
          this.listLink()
        } 
        else {
          console.log("deletePeriod ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }      
      },

      dialogClose(){
        this.showDialog = false
      },

      async dialogSave(){
        if(this.record.name.trim()==='' || this.record.url.trim()==='')
          this.$alert("Semua data harus dimasukkan")
        else{
          var e1 = document.getElementById("selectAccess")
          switch(e1.selectedIndex) {
            case 0: this.record.access = "USER"; break;
            case 1: this.record.access = "LEADER"; break;
            case 2: this.record.access = "ADMIN"; break;
          }
          this.showDialog = false

          if(this.isEdit){
            this.showLoading = true
            let apiRes = await api.updateLink(this.user.id, this.record.id, this.record.name, this.record.url, this.record.access)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listLink()
            } 
            else {
              console.log("updateLink ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
          else{
            this.showLoading = true
            let apiRes = await api.createLink(this.user.id, this.record.name, this.record.url, this.record.access)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listLink()
            } 
            else {
              console.log("createLink ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
        }
      },

      onClickNewLink(){
        this.isEdit = false
        this.title = "Buat Link baru"

        this.record = { name:'', url: '', access: 'USER'}
        this.showDialog = true
      },

    },

  }
</script>
